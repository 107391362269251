import { Lines, Mesh, GLMesh, GLLines, Plane } from '@zeainc/zea-engine'

import { drawShaderAttribsStride } from './CADConstants.js'

/** This class abstracts the rendering of a collection of geometries to screen.
 * @extends Lines
 * @ignore
 */
class SurfaceNormals extends Lines {
  /**
   * Create surface normals.
   * @param {number} u - The u value.
   * @param {number} v - The v value.
   */
  constructor(u, v) {
    super()
    this.setNumSegments(u * v)
    this.setNumVertices(u * v * 2)
    const positions = this.getVertexAttribute('positions')
    for (let i = 0; i < v; i++) {
      const y = i / (v - 1) - 0.5
      for (let j = 0; j < u; j++) {
        const x = j / (u - 1) - 0.5
        const id = i * u + j
        positions.getValueRef(id * 2).set(x, y, 0.0)
        positions.getValueRef(id * 2 + 1).set(x, y, 1.0)
        this.setSegmentVertexIndices(id, id * 2, id * 2 + 1)
      }
    }
  }
}

/** Class representing a fan.
 * @extends Mesh
 * @ignore
 */
class Fan extends Mesh {
  /**
   * Create a fan.
   * @param {any} vertexCount - The vertexCount value.
   */
  constructor(vertexCount) {
    super()
    this.setNumVertices(vertexCount)
    const faceCount = vertexCount - 2
    this.setFaceCounts([faceCount])

    // ////////////////////////////
    // build the topology
    for (let j = 0; j < faceCount; j++) {
      this.setFaceVertexIndices(j, [0, j + 1, j + 2])
    }
    const positions = this.getVertexAttribute('positions')
    for (let i = 0; i < vertexCount; i++) {
      // Note: the 'x,y' values are used as uv coords
      // to look up the actual vertex values in the texture.
      // (with a 0.5, 0.5 offset)
      positions.getValueRef(i).set(i / vertexCount - 0.5, -0.5, 0.0)
    }
  }
}

/** Class representing a sub set.
 * @ignore
 */
class SubSet {
  /**
   * Create a sub set.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    this.__gl = gl
    this.__drawCoordsArray = null
    this.__drawCoordsBuffer = null
    this.__drawCount = 0 // The number of visible drawn geoms.

    this.__bindAttr = (location, channels, type, stride, offset, instanced = true) => {
      gl.enableVertexAttribArray(location)
      gl.vertexAttribPointer(location, channels, type, false, stride, offset)
      if (instanced) gl.vertexAttribDivisor(location, 1) // This makes it instanced
    }
  }

  /**
   * The setDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   */
  setDrawItems(itemsArray) {
    if (this.__drawCoordsBuffer) {
      this.__gl.deleteBuffer(this.__drawCoordsBuffer)
      this.__drawCoordsBuffer = null
    }
    const gl = this.__gl
    this.__drawCoordsBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, this.__drawCoordsBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, itemsArray, gl.STATIC_DRAW)
    this.__drawCount = itemsArray.length / drawShaderAttribsStride
    return this.__drawCount
  }

  /**
   * The addDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   */
  addDrawItems(itemsArray) {
    // console.log("addDrawItems:" + itemsArray);
    if (!this.__drawCoordsArray) {
      this.__drawCoordsArray = itemsArray
    } else {
      const new_Array = new Float32Array(this.__drawCoordsArray.length + itemsArray.length)
      new_Array.set(this.__drawCoordsArray)
      new_Array.set(itemsArray, this.__drawCoordsArray.length)
      this.__drawCoordsArray = new_Array
    }

    if (this.__drawCoordsBuffer) {
      this.__gl.deleteBuffer(this.__drawCoordsBuffer)
      this.__drawCoordsBuffer = null
    }

    const gl = this.__gl
    this.__drawCoordsBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, this.__drawCoordsBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, this.__drawCoordsArray, gl.STATIC_DRAW)

    this.__drawCount += itemsArray.length / drawShaderAttribsStride
    return this.__drawCount
  }

  /**
   * The getDrawCount method.
   * @return {any} - The return value.
   */
  getDrawCount() {
    return this.__drawCount
  }

  // ////////////////////////////////////
  // Drawing

  /**
   * The bind method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  bind(renderstate) {
    if (this.__drawCount == 0) {
      return 0
    }

    const gl = this.__gl
    gl.bindBuffer(gl.ARRAY_BUFFER, this.__drawCoordsBuffer)

    const attrs = renderstate.attrs
    this.__bindAttr(attrs.drawCoords.location, 4, gl.FLOAT, drawShaderAttribsStride * 4, 0)
    // this.__bindAttr(attrs.drawItemTexAddr.location, 2, gl.FLOAT, drawShaderAttribsStride * 4, 4 * 4)

    return this.__drawCount
  }

  destroy() {
    const gl = this.__gl
    gl.deleteBuffer(this.__drawCoordsBuffer)
    this.__drawCoordsBuffer = null
  }
}

const __cache = {}

/** Class representing a GL surface draw set.
 * @ignore
 */
class GLSurfaceDrawSet {
  /**
   * Create a GL surface draw set.
   * @param {any} gl - The gl value.
   * @param {number} x - The x value.
   * @param {number} y - The y value.
   */
  constructor(gl, x, y) {
    // console.log("GLSurfaceDrawSet:" + x + "," + y)
    this.__gl = gl

    if (x == 0 || y == 0) console.error('invalid GLSurfaceDrawSet:' + x + ',' + y)

    if (y == 1) {
      const key = x
      if (!__cache[key]) {
        __cache[key] = new GLMesh(gl, new Fan(x))
      }
      this.__glgeom = __cache[key]
      this.__numTris = x - 2
      this.__glnormalsgeom = new GLLines(gl, new SurfaceNormals(x, y))

      this.key = key
    } else {
      const key = x + 'x' + y
      if (!__cache[key]) {
        __cache[key] = new GLMesh(gl, new Plane(1.0, 1.0, x - 1, y - 1))
      }
      this.__glgeom = __cache[key]
      this.__numTris = (x - 1) * (y - 1) * 2
      this.__glnormalsgeom = new GLLines(gl, new SurfaceNormals(x, y))

      this.key = key
    }
    this.__quadDetail = [x - 1, y - 1]
    this.__freeIndices = []
    this.__subSets = {}
  }

  /**
   * The setDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   * @param {any} key - The key param.
   * @return {any} - The return value.
   */
  setDrawItems(itemsArray, key) {
    if (!this.__subSets[key]) {
      this.__subSets[key] = new SubSet(this.__gl)
    }
    const drawCount = this.__subSets[key].setDrawItems(itemsArray)
    return this.__numTris * drawCount
  }

  /**
   * The addDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   * @param {any} key - The key param.
   * @return {any} - The return value.
   */
  addDrawItems(itemsArray, key) {
    if (!this.__subSets[key]) {
      this.__subSets[key] = new SubSet(this.__gl)
    }
    const drawCount = this.__subSets[key].addDrawItems(itemsArray)
    // console.log(this.key, "key:", key, drawCount)

    return this.__numTris * drawCount
  }

  /**
   * The getDrawCount method.
   * @param {any} key - The key param.
   * @return {number} - The return value.
   */
  getDrawCount(key) {
    if (this.__subSets[key]) return this.__subSets[key].getDrawCount()
    return 0
  }

  // ////////////////////////////////////
  // Drawing

  /**
   * The draw method.
   * @param {any} renderstate - The renderstate param.
   * @param {any} key - The key param.
   */
  draw(renderstate, key) {
    const subSet = this.__subSets[key]
    if (!subSet) return

    const gl = this.__gl
    const unifs = renderstate.unifs

    if (unifs.quadDetail) {
      gl.uniform2i(unifs.quadDetail.location, this.__quadDetail[0], this.__quadDetail[1])
    }

    this.__glgeom.bind(renderstate)

    const drawCount = subSet.bind(renderstate)

    renderstate.bindViewports(renderstate.unifs, () => {
      this.__glgeom.drawInstanced(drawCount)

      // To debug the mesh topology we can render as lines instead.
      // gl.drawElementsInstanced(
      //   this.__glgeom.__gl.LINES,
      //   this.__glgeom.__numTriIndices,
      //   this.__glgeom.__indexDataType,
      //   0,
      //   drawCount
      // )
    })
  }

  /**
   * The drawNormals method.
   * @param {any} renderstate - The renderstate param.
   * @param {any} key - The key param.
   */
  drawNormals(renderstate, key) {
    if (!this.__glnormalsgeom) return

    const subSet = this.__subSets[key]
    if (!subSet) return
    const gl = this.__gl
    const unifs = renderstate.unifs

    if (unifs.quadDetail) {
      gl.uniform2i(unifs.quadDetail.location, this.__quadDetail[0], this.__quadDetail[1])
    }

    this.__glnormalsgeom.bind(renderstate)

    const drawCount = subSet.bind(renderstate)

    renderstate.bindViewports(renderstate.unifs, () => {
      this.__glnormalsgeom.drawInstanced(drawCount)
    })
  }

  destroy() {
    // Note:  this.__glgeom is shared between all GLCADAssets using a global cache. See above
    // this.__glgeom.destroy()

    if (this.__glnormalsgeom) this.__glnormalsgeom.destroy()

    for (const key in this.__subSets) {
      let subSet = this.__subSets[key]
      subSet.destroy()
    }
  }
}

export { GLSurfaceDrawSet }
