import { shaderLibrary, Registry } from '@zeainc/zea-engine'
import { GLDrawCADSurfaceShader_VERTEX_SHADER } from './GLDrawCADSurfaceShader.js'

const FRAGMENT_SHADER = `
precision highp float;

<%include file="stack-gl/gamma.glsl"/>
<%include file="GLSLCADConstants.glsl"/>
<%include file="GLSLUtils.glsl"/>
<%include file="GLSLBinReader.glsl"/>

varying vec4 v_drawCoords;
varying vec3 v_viewPos;
varying vec3 v_worldPos;
varying vec3 v_viewNormal;
varying vec2 v_textureCoord;
varying float v_surfaceType;
varying vec2 v_quadDetail;

<%include file="GLSLCADSurfaceDrawing.fragmentShader.glsl"/>

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
    vec4 fragColor;
#endif
    
    int cadBodyId = int(floor(v_drawCoords.r + 0.5));
    int surfaceIndexInBody = int(floor(v_drawCoords.g + 0.5));
    int surfaceId = int(floor(v_drawCoords.b + 0.5));
    int trimSetId = int(floor(v_drawCoords.a + 0.5));

    // TODO: pass as varying from pixel shader.
    vec4 cadBodyPixel0 = getCADBodyPixel(cadBodyId, 0);
    int flags = int(floor(cadBodyPixel0.g + 0.5));
            

    //////////////////////////////////////////////
    // Cutaways
    if (testFlag(flags, BODY_FLAG_CUTAWAY)) {
        vec4 cadBodyPixel6 = getCADBodyPixel(cadBodyId, 6);
        vec3 cutNormal = cadBodyPixel6.xyz;
        float cutPlaneDist = cadBodyPixel6.w;
        if (cutaway(v_worldPos, cutNormal, cutPlaneDist)) {
            discard;
        }
    }

    //////////////////////////////////////////////
    // Trimming
    vec4 trimPatchQuad;
    vec3 trimCoords;
    if(trimSetId >= 0) {
        GLSLBinReader trimsetLayoutDataReader;
        GLSLBinReader_init(trimsetLayoutDataReader, trimSetsAtlasLayoutTextureSize, 16);
        trimPatchQuad = GLSLBinReader_readVec4(trimsetLayoutDataReader, trimSetsAtlasLayoutTexture, trimSetId*4);

        if(applyTrim(trimPatchQuad, trimCoords, flags)){
            discard;
            return;
        }
    }
    
    vec4 highlightColor = getCADBodyPixel(cadBodyId, 5);
    fragColor = highlightColor;

#ifndef ENABLE_ES3
    gl_FragColor = fragColor;
#endif
}
`

import { GLCADShader } from './GLCADShader.js'

/** Class representing a GL draw selected CAD surface shader.
 * @extends GLCADShader
 * @ignore
 */
class GLDrawSelectedCADSurfaceShader extends GLCADShader {
  /**
   * Create a GL draw selected CAD surface shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.setShaderStage('VERTEX_SHADER', GLDrawCADSurfaceShader_VERTEX_SHADER)
    this.setShaderStage('FRAGMENT_SHADER', FRAGMENT_SHADER)
    this.nonSelectable = true
  }
}

Registry.register('GLDrawSelectedCADSurfaceShader', GLDrawSelectedCADSurfaceShader)

export { GLDrawSelectedCADSurfaceShader }
