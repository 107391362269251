import { Lines, GLLines } from '@zeainc/zea-engine'
import { drawShaderAttribsStride } from './CADConstants.js'

/** Class representing an Edge
 * @private
 */
class Edge extends Lines {
  /**
   * Create a strip.
   * @param {number} detail - The detail value.
   */
  constructor(detail = 1) {
    super()
    this.setNumVertices(detail + 1)
    this.setNumSegments(detail)
    const positions = this.getVertexAttribute('positions')
    for (let i = 0; i <= detail; i++) {
      if (i < detail) this.setSegmentVertexIndices(i, i, i + 1)
      // Note: the 'x,y' values are used as uv coords
      // to look up the actual vertex values in the texture.
      // (with a 0.5, 0.5 offset)
      positions.getValueRef(i).set(i / detail, 0.0, 0.0)
    }
    this.emit('geomDataTopologyChanged')
  }
}

/** Class representing a sub set.
 * @private
 */
class SubSet {
  /**
   * Create a sub set.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    this.__gl = gl
    this.__drawCoordsArray = null
    this.__drawCoordsBuffer = null
    this.__drawCount = 0 // The number of visible drawn geoms.

    this.__bindAttr = (location, channels, type, stride, offset, instanced = true) => {
      gl.enableVertexAttribArray(location)
      gl.vertexAttribPointer(location, channels, type, false, stride, offset)
      if (instanced) gl.vertexAttribDivisor(location, 1) // This makes it instanced
    }
  }

  /**
   * The setDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   */
  setDrawItems(itemsArray) {
    if (this.__drawCoordsBuffer) {
      this.__gl.deleteBuffer(this.__drawCoordsBuffer)
      this.__drawCoordsBuffer = null
    }
    const gl = this.__gl
    this.__drawCoordsBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, this.__drawCoordsBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, itemsArray, gl.STATIC_DRAW)
    this.__drawCount = itemsArray.length / drawShaderAttribsStride
    return this.__drawCount
  }

  /**
   * The addDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   */
  addDrawItems(itemsArray) {
    // console.log("addDrawItems:" + itemsArray);
    if (!this.__drawCoordsArray) {
      this.__drawCoordsArray = itemsArray
    } else {
      const new_Array = new Float32Array(this.__drawCoordsArray.length + itemsArray.length)
      new_Array.set(this.__drawCoordsArray)
      new_Array.set(itemsArray, this.__drawCoordsArray.length)
      this.__drawCoordsArray = new_Array
    }

    if (this.__drawCoordsBuffer) {
      this.__gl.deleteBuffer(this.__drawCoordsBuffer)
      this.__drawCoordsBuffer = null
    }

    const gl = this.__gl
    this.__drawCoordsBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, this.__drawCoordsBuffer)
    gl.bufferData(gl.ARRAY_BUFFER, this.__drawCoordsArray, gl.STATIC_DRAW)

    this.__drawCount += itemsArray.length / drawShaderAttribsStride
    return this.__drawCount
  }

  /**
   * The getDrawCount method.
   * @return {any} - The return value.
   */
  getDrawCount() {
    return this.__drawCount
  }

  // ////////////////////////////////////
  // Drawing

  /**
   * The bind method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  bind(renderstate) {
    if (this.__drawCount == 0) {
      return 0
    }
    const gl = this.__gl
    gl.bindBuffer(gl.ARRAY_BUFFER, this.__drawCoordsBuffer)

    const attrs = renderstate.attrs
    this.__bindAttr(attrs.drawCoords.location, 4, gl.FLOAT, drawShaderAttribsStride * 4, 0)
    // this.__bindAttr(attrs.drawItemTexAddr.location, 2, gl.FLOAT, drawShaderAttribsStride * 4, 4 * 4)

    return this.__drawCount
  }

  destroy() {
    const gl = this.__gl
    gl.deleteBuffer(this.__drawCoordsBuffer)
    this.__drawCoordsBuffer = null
  }
}

const __cache = {}

/** Class representing a GL surface draw set.
 * @private
 */
class GLCurveDrawSet {
  /**
   * Create a GL surface draw set.
   * @param {any} gl - The gl value.
   * @param {number} x - The x value.
   * @param {number} y - The y value.
   */
  constructor(gl, detail) {
    // console.log("GLCurveDrawSet:" + x + "," + y)
    this.__gl = gl

    if (detail == 0) console.error('invalid GLCurveDrawSet:' + detail)

    if (!__cache[detail]) {
      __cache[detail] = new GLLines(gl, new Edge(detail))
    }
    this.key = detail
    this.__glgeom = __cache[detail]
    this.__edgeDetail = detail
    this.__freeIndices = []
    this.__subSets = {}
    this.__numDrawItems = 0
  }

  /**
   * The setDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   * @param {any} key - The key param.
   * @return {any} - The return value.
   */
  setDrawItems(itemsArray, key) {
    if (!this.__subSets[key]) {
      this.__subSets[key] = new SubSet(this.__gl)
    }
    this.__subSets[key].setDrawItems(itemsArray)

    this.__numDrawItems += itemsArray.length / 2
    return this.__numDrawItems
  }

  /**
   * The addDrawItems method.
   * @param {any} itemsArray - The itemsArray param.
   * @param {any} key - The key param.
   * @return {any} - The return value.
   */
  addDrawItems(itemsArray, key) {
    if (!this.__subSets[key]) {
      this.__subSets[key] = new SubSet(this.__gl)
    }
    this.__numDrawItems += this.__subSets[key].addDrawItems(itemsArray)
    // console.log(this.key, "key:", key, this.__numDrawItems)
  }

  /**
   * The getDrawCount method.
   * @param {any} key - The key param.
   * @return {number} - The return value.
   */
  getDrawCount(key) {
    if (this.__subSets[key]) return this.__subSets[key].getDrawCount()
    return 0
  }

  // ////////////////////////////////////
  // Drawing

  /**
   * The draw method.
   * @param {any} renderstate - The renderstate param.
   * @param {any} key - The key param.
   */
  draw(renderstate, key) {
    const subSet = this.__subSets[key]
    if (!subSet) return

    const gl = this.__gl
    const unifs = renderstate.unifs

    if (unifs.edgeDetail) {
      gl.uniform1i(unifs.edgeDetail.location, this.__edgeDetail)
    }

    this.__glgeom.bind(renderstate)

    const drawCount = subSet.bind(renderstate)
    renderstate.bindViewports(renderstate.unifs, () => {
      this.__glgeom.drawInstanced(drawCount)
    })
  }

  destroy() {
    // Note:  this.__glgeom is shared between all GLCADAssets using a global cache. See above
    // this.__glgeom.destroy()

    if (this.__glnormalsgeom) this.__glnormalsgeom.destroy()

    for (const key in this.__subSets) {
      let subSet = this.__subSets[key]
      subSet.destroy()
    }
  }
}

export { GLCurveDrawSet }
