﻿import { SystemDesc, GLMesh, Plane, GLPass, Registry } from '@zeainc/zea-engine'
import { CADAsset } from './CADAsset.js'
import { GLCADAsset } from './GLCADAsset.js'
import { GLCADMaterialLibrary } from './GLCADMaterialLibrary.js'

import { GLDrawCADSurfaceNormalsShader } from './GLDrawCADSurfaceNormalsShader.js'
import { GLDrawCADSurfaceGeomDataShader } from './GLDrawCADSurfaceGeomDataShader.js'
import { GLDrawSelectedCADSurfaceShader } from './GLDrawSelectedCADSurfaceShader.js'
import { GLDrawCADCurveShader } from './GLDrawCADCurveShader.js'

/**
 * Class representing a GL CAD pass.
 *
 * **Events**
 * * **updated**
 * @extends GLPass
 */
class GLCADPass extends GLPass {
  /**
   * Create a GL CAD pass.
   * @param {boolean} debugMode - If true, then puts the GLCADPass rendering into debug mode.
   */
  constructor(debugMode = false) {
    super()
    this.debugMode = debugMode
    this.headLighting = false
    this.displayWireframes = false
    this.displaySurfaces = true
    this.__displayEdges = 0
    this.displayNormals = false
    this.normalLength = 0.002 // 2cm
    this.debugTrimTex = false
    this.debugSurfaceAtlas = false
    this.debugAssetId = 0
    this.pbrEnabled = false
    this.__assets = []
    this.__loadQueue = 0

    this.__numHighlightedGeoms = 0

    // Note: fist id reserved for selectionOutlineID = 1
    // See 'draw()' below.
    this.__shaderCount = 2
    this.__shaderKeys = {}
    this.__shaderOptsStack = [{}]

    this.__profiling = {
      numSurfaces: 0,
      numSurfaceInstances: 0,
      surfaceEvalTime: 0,
      numBodies: 0,
      numMaterials: 0,
      numTriangles: 0,
      numDrawSets: 0,
    }
  }

  // eslint-disable-next-line require-jsdoc
  get displayEdges() {
    return this.__displayEdges > 0
  }

  // eslint-disable-next-line require-jsdoc
  set displayEdges(val) {
    if (val == true) this.__displayEdges++
    else this.__displayEdges--
    this.emit('updated')
  }

  /**
   * The init method.
   * @param {any} renderer - The renderer param.
   * @param {any} passIndex - The passIndex param.
   */
  init(renderer, passIndex) {
    super.init(renderer, passIndex)

    this.__dataLoadStartTime = performance.now()

    const gl = renderer.gl

    const materialLibrary = new GLCADMaterialLibrary(gl)
    materialLibrary.on('updated', () => this.emit('updated'))

    // Note: The crappy browsers don't support GLSL binary caching, so
    // load times get quite long as we wait for the big shaders to compile.

    if (gl.name != 'webgl2') {
      this.setShaderPreprocessorValue('#extension GL_OES_standard_derivatives : enable')
    }

    if (this.debugMode) {
      this.setShaderPreprocessorValue('#define DEBUG_MODE')
    }

    this.setShaderPreprocessorValue('#define ENABLE_TRIMMING')
    this.setShaderPreprocessorValue('#define ENABLE_INLINE_GAMMACORRECTION')

    this.__cadpassdata = {
      debugMode: this.debugMode,
      assetCount: 0,
      materialLibrary,

      glplanegeom: new GLMesh(gl, new Plane(1.0, 1.0, 1, 1)),
      maxTexSize: SystemDesc.gpuDesc.maxTextureSize,

      incHighlightedCount: this.incHighlightedCount.bind(this),
      decHighlightedCount: this.decHighlightedCount.bind(this),

      incDisplayEdges: () => {
        this.__displayEdges++
        this.emit('updated')
      },
      decDisplayEdges: () => {
        this.__displayEdges--
        this.emit('updated')
      },
      genShaderID: (shaderName) => {
        if (shaderName == 'SimpleSurfaceShader' || shaderName == 'StandardSurfaceShader') {
          shaderName = 'GLDrawCADSurfaceShader'
        }
        if (!(shaderName in this.__shaderKeys)) {
          const shaderClass = Registry.getBlueprint(shaderName)
          if (!shaderClass || !shaderClass.getPackedMaterialData) {
            return this.__cadpassdata.genShaderID('GLDrawCADSurfaceShader')
          }
          const shader = this.applyOptsToShader(Registry.constructClass(shaderName, gl))

          const id = this.__shaderCount
          this.__shaderKeys[shaderName] = {
            id,
            shader,
          }
          this.__shaderCount++
          return id
        }
        return this.__shaderKeys[shaderName].id
      },
    }

    this.__decrementLoadQueue = () => {
      this.__loadQueue--
      if (this.__loadQueue == 0) {
        // console.log('===All Assets Loaded===')
        // console.log('Total Load Time:' + (performance.now() - this.__gpuLoadStartTime) / 1000)
        this.__profiling.numTriangles = this.__profiling.numTriangles / 1000000
        // console.log(this.__profiling)

        this.emit('updated')
      }
    }

    // collector.registerSceneItemFilter((treeItem, rargs) => {
    //   if (treeItem instanceof CADAsset) {
    //     this.__loadQueue++;
    //     const cadAsset = treeItem;
    //     cadAsset.on('loaded', () => {
    //       this.addCADAsset(treeItem);
    //     });
    //     rargs.continueInSubTree = true;
    //     return true;
    //   }
    // });
  }

  /**
   * The itemAddedToScene method is called on each pass when a new item
   * is added to the scene, and the renderer must decide how to render it.
   * It allows Passes to select geometries to handle the drawing of.
   * @param {TreeItem} treeItem - The treeItem value.
   * @param {object} rargs - Extra return values are passed back in this object.
   * The object contains a parameter 'continueInSubTree', which can be set to false,
   * so the subtree of this node will not be traversed after this node is handled.
   * @return {Boolean} - The return value.
   */
  itemAddedToScene(treeItem, rargs) {
    if (treeItem instanceof CADAsset) {
      const cadAsset = treeItem
      this.__loadQueue++
      this.__cadpassdata.assetCount++

      if (cadAsset.isLoaded()) {
        if (cadAsset.getSurfaceLibrary().getNumSurfaces() > 0) {
          this.addCADAsset(cadAsset)
        } else {
          this.__decrementLoadQueue()
        }
      } else {
        cadAsset.once('loaded', () => {
          if (cadAsset.getSurfaceLibrary().getNumSurfaces() > 0) this.addCADAsset(cadAsset)
          else {
            this.__decrementLoadQueue()
          }
        })
      }
      rargs.continueInSubTree = true
      return true
    }
    return false
  }

  /**
   * The itemRemovedFromScene method is called on each pass when aa item
   * is removed to the scene, and the pass must handle cleaning up any resources.
   * @param {TreeItem} treeItem - The treeItem value.
   * @param {object} rargs - Extra return values are passed back in this object.
   * @return {Boolean} - The return value.
   */
  itemRemovedFromScene(treeItem, rargs) {
    if (treeItem instanceof CADAsset) {
      this.removeCADAsset(treeItem)
      return true
    }
    return false
  }

  /**
   * The getShaderPreprocessorValue method.
   * @param {any} name - The name param.
   * @return {any} - The return value.
   */
  getShaderPreprocessorValue(name) {
    return this.getShaderState()[name]
  }

  /**
   * The setShaderPreprocessorValue method.
   * @param {any} name - The name param.
   * @param {boolean} apply - The apply param.
   */
  setShaderPreprocessorValue(name, apply = true) {
    if (!name.startsWith('#')) name = '#define ' + name

    this.getShaderState()[name] = name

    // Now update any shaders already consturcted.
    // eslint-disable-next-line guard-for-in
    for (const shaderKey in this.__shaderKeys) {
      const shaderReg = this.__shaderKeys[shaderKey]
      if (shaderReg.shader.setPreprocessorValue) {
        shaderReg.shader.setPreprocessorValue(name)
        if (apply) shaderReg.shader.applyOptions()
      }
    }

    if (this.__drawSelectedCADSurfaceShader) {
      this.__drawSelectedCADSurfaceShader.setPreprocessorValue(name)
      if (apply) this.__drawSelectedCADSurfaceShader.applyOptions()
    }
    if (this.__drawCADSurfaceGeomDataShader) {
      this.__drawCADSurfaceGeomDataShader.setPreprocessorValue(name)
      if (apply) this.__drawCADSurfaceGeomDataShader.applyOptions()
    }
    if (this.__renderer) this.__renderer.requestRedraw()
  }

  /**
   * The clearShaderPreprocessorValue method.
   * @param {any} name - The name param.
   * @param {boolean} apply - The apply param.
   */
  clearShaderPreprocessorValue(name, apply = true) {
    delete this.getShaderState()[name]

    // Now update any shaders already consturcted.
    // eslint-disable-next-line guard-for-in
    for (const shaderKey in this.__shaderKeys) {
      const shaderReg = this.__shaderKeys[shaderKey]
      if (shaderReg.shader.clearPreprocessorValue) {
        shaderReg.shader.clearPreprocessorValue(name)
        if (apply) shaderReg.shader.applyOptions()
      }
    }
    if (this.__renderer) this.__renderer.requestRedraw()
  }

  /**
   * Applies shader options to the compiled shaders, recompiling if necessary.
   * @param {Shader} shader - The shader.
   * @return {Shader} - The updated shader
   */
  applyOptsToShader(shader) {
    if (shader.setPreprocessorValue) {
      // Initialise the shaders.
      const opts = this.getShaderState()
      // eslint-disable-next-line guard-for-in
      for (const key in opts) shader.setPreprocessorValue(key)
      shader.applyOptions()
    }
    return shader
  }

  /**
   * The getShaderState method.
   * @return {any} - The return value.
   */
  getShaderState() {
    return this.__shaderOptsStack[this.__shaderOptsStack.length - 1]
  }

  /**
   * The pushShaderState method.
   */
  pushShaderState() {
    this.__shaderOptsStack.push(Object.assign({}, this.getShaderState()))
    // eslint-disable-next-line guard-for-in
    for (const shaderKey in this.__shaderKeys) {
      const shaderReg = this.__shaderKeys[shaderKey]
      if (shaderReg.shader.pushState) {
        shaderReg.shader.pushState()
      }
    }
  }

  /**
   * The popShaderState method.
   */
  popShaderState() {
    this.__shaderOptsStack.pop()
    // eslint-disable-next-line guard-for-in
    for (const shaderKey in this.__shaderKeys) {
      const shaderReg = this.__shaderKeys[shaderKey]
      if (shaderReg.shader.popState) shaderReg.shader.popState()
    }
  }

  /**
   * The startPresenting method.
   */
  startPresenting() {
    if (SystemDesc.deviceCategory != 'High') {
      this.pushShaderState()
    }
  }

  /**
   * The stopPresenting method.
   */
  stopPresenting() {
    if (SystemDesc.deviceCategory != 'High') {
      this.popShaderState()
    }
  }

  /**
   * The getCutPlaneNormalParam method.
   * @return {any} - The return value.
   */
  getCutPlaneNormalParam() {
    return this.__cutPlaneNormalParam
  }

  /**
   * The getCutPlaneDistParam method.
   * @return {any} - The return value.
   */
  getCutPlaneDistParam() {
    return this.__cutDistParam
  }

  /**
   * The getCutPlaneColorParam method.
   * @return {any} - The return value.
   */
  getCutPlaneColorParam() {
    return this.__cutPlaneColorParam
  }

  /**
   * The incHighlightedCount method.
   * @param {any} count - The count param.
   */
  incHighlightedCount(count) {
    this.__numHighlightedGeoms += count
  }

  /**
   * The decHighlightedCount method.
   * @param {any} count - The count param.
   */
  decHighlightedCount(count) {
    this.__numHighlightedGeoms -= count
  }

  /**
   * The addCADAsset method is an internal method called when new CADAsset
   * items are discovered in the tree.
   * @param {CADAsset} cadAsset - The cadAsset tree item.
   */
  addCADAsset(cadAsset) {
    this.__gl.finish()
    const assetId = this.__assets.length

    if (assetId == 0) {
      this.__gpuLoadStartTime = performance.now()
    }

    if (cadAsset.getVersion().compare([0, 0, 26]) > 0) {
      this.setShaderPreprocessorValue('#define INTS_PACKED_AS_2FLOAT16')
    }
    if (cadAsset.getVersion().compare([0, 0, 29]) >= 0) {
      this.setShaderPreprocessorValue('#define ENABLE_PER_FACE_COLORS')
    }
    if (cadAsset.getVersion().compare([1, 0, 5]) >= 0) {
      this.setShaderPreprocessorValue('#define ENABLE_BODY_EDGES')
    }

    const glcadAsset = new GLCADAsset(this.__gl, assetId, cadAsset, this.__cadpassdata)

    glcadAsset.once('loaded', (assetStats) => {
      this.__profiling.numSurfaces += assetStats.numSurfaces
      this.__profiling.numSurfaceInstances += assetStats.numSurfaceInstances
      this.__profiling.surfaceEvalTime += assetStats.surfaceEvalTime
      this.__profiling.numBodies += assetStats.numBodies
      this.__profiling.numMaterials += assetStats.numMaterials
      this.__profiling.numTriangles += assetStats.numTriangles
      this.__profiling.numDrawSets += assetStats.numDrawSets

      this.__decrementLoadQueue()
    })

    glcadAsset.on('updated', () => this.emit('updated'))

    this.__assets.push(glcadAsset)
  }

  /**
   * The removeCADAsset method.
   * @param {CADAsset} asset - The cadAsset to remove.
   */
  removeCADAsset(asset) {
    this.__assets = this.__assets.filter((glcadAsset) => {
      if (glcadAsset.getCADAsset() == asset) {
        glcadAsset.destroy()
        return false
      }
      return true
    })
    this.emit('updated')
  }

  /**
   * The getGLCADAsset method.
   * @param {number} index - The index of the cadAsset to retrieve.
   * @return {CADAsset} - The return value.
   */
  getGLCADAsset(index) {
    return this.__assets[index]
  }

  /**
   * The draw method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  draw(renderstate) {
    const gl = this.__gl

    if (this.__profiling.numBodies == 0) return
    if (SystemDesc.isIOSDevice) {
      throw new Error('The ZeaCAD cannot be supported on iOS due to no ability to render to a FLOAT framebuffer.')
    }

    if (this.debugTrimTex) {
      if (this.__assets.length > this.debugAssetId) this.__assets[this.debugAssetId].drawTrimSets(renderstate)
    }
    if (this.debugSurfaceAtlas) {
      if (this.__assets.length > this.debugAssetId) this.__assets[this.debugAssetId].drawSurfaceAtlas(renderstate)
      return
    }

    if (this.displaySurfaces) {
      if (!this.pbrEnabled && renderstate.envMap) {
        // If an env map is detected, automatically enable PBR rendering.
        this.setShaderPreprocessorValue('#define ENABLE_PBR')
        this.pbrEnabled = true
      }

      if (this.__cadpassdata.materialLibrary.needsUpload()) this.__cadpassdata.materialLibrary.uploadMaterials()

      // eslint-disable-next-line guard-for-in
      for (const shaderKey in this.__shaderKeys) {
        const shaderReg = this.__shaderKeys[shaderKey]
        shaderReg.shader.bind(renderstate)
        renderstate.shaderId = shaderReg.id

        if (!this.__cadpassdata.materialLibrary.bind(renderstate)) {
          return false
        }

        if (renderstate.unifs.headLighting) {
          gl.uniform1i(renderstate.unifs.headLighting.location, this.headLighting)
        }
        if (renderstate.unifs.displayWireframes) {
          gl.uniform1i(renderstate.unifs.displayWireframes.location, this.displayWireframes)
        }

        const boundTextures = renderstate.boundTextures
        for (const asset of this.__assets) {
          asset.draw(renderstate)
          renderstate.boundTextures = boundTextures
        }

        shaderReg.shader.unbind(renderstate)
      }
    }

    if (this.displayNormals) {
      if (!this.__drawCADSurfaceNormalsShader) {
        this.__drawCADSurfaceNormalsShader = this.applyOptsToShader(new GLDrawCADSurfaceNormalsShader(gl))
      }
      if (!this.__drawCADSurfaceNormalsShader.bind(renderstate)) return false

      gl.uniform1f(renderstate.unifs.normalLength.location, this.normalLength)
      const id = this.__shaderKeys.GLDrawCADSurfaceShader.id
      const boundTextures = renderstate.boundTextures
      for (const asset of this.__assets) {
        asset.drawNormals(renderstate, id)
        renderstate.boundTextures = boundTextures
      }
    }

    if (this.__displayEdges > 0) {
      if (!this.__drawCADCurvesShader) {
        this.__drawCADCurvesShader = this.applyOptsToShader(new GLDrawCADCurveShader(gl))
      }
      if (!this.__drawCADCurvesShader.bind(renderstate)) return false

      gl.uniform4f(renderstate.unifs.edgeColor.location, 0.1, 0.1, 0.1, 1)

      gl.enable(gl.BLEND)
      gl.blendEquation(gl.FUNC_ADD)
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA) // For add

      const boundTextures = renderstate.boundTextures
      for (const asset of this.__assets) {
        asset.drawEdges(renderstate, 0)
        renderstate.boundTextures = boundTextures
      }

      gl.disable(gl.BLEND)
    }

    // To debug the highlight buffer, enable this line.
    // It will draw the highlight buffer directly to the screen.
    // this.drawHighlightedGeoms(renderstate)
  }

  /**
   * The drawHighlightedGeoms method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  drawHighlightedGeoms(renderstate) {
    if (this.__numHighlightedGeoms == 0) return false
    const gl = this.__gl
    if (!this.__drawSelectedCADSurfaceShader) {
      this.__drawSelectedCADSurfaceShader = this.applyOptsToShader(new GLDrawSelectedCADSurfaceShader(gl))
    }
    if (!this.__drawSelectedCADSurfaceShader.bind(renderstate)) {
      return false
    }
    for (const asset of this.__assets) {
      asset.drawHighlightedGeoms(renderstate)
    }
  }

  /**
   * The drawGeomData method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  drawGeomData(renderstate) {
    if (this.__profiling.numBodies == 0) return
    if (SystemDesc.isIOSDevice) {
      throw new Error('The ZeaCAD cannot be supported on iOS due to no ability to render to a FLOAT framebuffer.')
    }

    const gl = this.__gl
    if (!this.__drawCADSurfaceGeomDataShader) {
      this.__drawCADSurfaceGeomDataShader = this.applyOptsToShader(new GLDrawCADSurfaceGeomDataShader(gl))
    }
    if (!this.__drawCADSurfaceGeomDataShader.bind(renderstate)) {
      return false
    }

    gl.disable(gl.BLEND)
    gl.disable(gl.CULL_FACE)
    gl.enable(gl.DEPTH_TEST)
    gl.depthFunc(gl.LESS)
    gl.depthMask(true)

    const passIndexUnif = renderstate.unifs.passIndex
    if (passIndexUnif) {
      gl.uniform1i(passIndexUnif.location, this.passIndex)
    }

    // eslint-disable-next-line guard-for-in
    for (const shaderKey in this.__shaderKeys) {
      const shaderReg = this.__shaderKeys[shaderKey]
      if (shaderReg.shader.nonSelectable) continue

      renderstate.shaderId = shaderReg.id
      for (const asset of this.__assets) {
        asset.drawGeomData(renderstate)
      }
    }
  }

  /**
   * The getGeomItemAndDist method.
   * @param {any} geomData - The geomData param.
   * @return {any} - The return value.
   */
  getGeomItemAndDist(geomData) {
    const assetId = Math.round(geomData[0] / 64)
    const geomId = Math.round(geomData[1])
    const dist = geomData[3]
    const geomItem = this.__assets[assetId].getGeomItem(geomId)

    // console.log(this.__assets[assetId].getSurfaceData(geomId))

    return {
      geomItem,
      dist,
    }
  }
}

export { GLCADPass }
