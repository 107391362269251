import { GLShader } from '@zeainc/zea-engine'
/** Class representing a GL CAD shader.
 * @extends GLShader
 * @ignore
 */
class GLCADShader extends GLShader {
  /**
   * Create a GL CAD shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)
    this.stack = [{}]
  }

  /**
   * The setPreprocessorValue method.
   * @param {any} name - The name param.
   */
  setPreprocessorValue(name) {
    this.getState()[name] = name
  }

  /**
   * The clearPreprocessorValue method.
   * @param {any} name - The name param.
   */
  clearPreprocessorValue(name) {
    delete this.getState()[name]
  }

  /**
   * The getState method.
   * @return {any} - The return value.
   */
  getState() {
    return this.stack[this.stack.length - 1]
  }

  /**
   * The pushState method.
   */
  pushState() {
    this.stack.push(Object.assign({}, this.getState()))
  }

  /**
   * The popState method.
   */
  popState() {
    this.stack.pop()
    this.applyOptions()
  }

  /**
   * The applyOptions method.
   */
  applyOptions() {
    const directives = [...this.__gl.shaderopts.directives]
    const state = this.getState()
    for (const key in state) {
      directives.push(state[key])
    }
    const defines = directives.join('\n') + '\n'
    this.__key = defines
    this.compileForTarget(this.__key, {
      directives,
    })
  }

  /**
   * The bind method.
   * @param {any} renderstate - The renderstate param.
   * @return {any} - The return value.
   */
  bind(renderstate) {
    return super.bind(renderstate, this.__key)
  }
}

export { GLCADShader }
