import { shaderLibrary } from '@zeainc/zea-engine'
shaderLibrary.setShaderModule(
  'GLSLMath.glsl',
  `

<%include file="stack-gl/transpose.glsl"/>

  ////////////////////////////////////////
  
  struct Xfo2d {
    vec2 tr;
    float rot;
    vec2 sc;
  };

  vec2 rotateVec2(float rot, vec2 vec){
    float cosa = cos(rot);
    float sina = sin(rot);
    return vec2(vec.x * cosa - vec.y * sina, vec.x * sina + vec.y * cosa);
  }

  vec2 Xfo2D_transformVec2(Xfo2d xfo2d, vec2 pos){
    return xfo2d.tr + rotateVec2(xfo2d.rot, pos * xfo2d.sc);
  }

  ////////////////////////////////////////


  vec4 quat_fromAxisAndAngle(vec3 axis, float angle) {
    float halfAngle = angle / 2.0;
    vec3 vec = axis* sin(halfAngle);
    return vec4(vec.x, vec.y, vec.z, cos(halfAngle));
  }

  vec4 quat_conjugate(vec4 quat) {
    return vec4(-quat.x, -quat.y, -quat.z, quat.w);
  }

  vec4 quat_multiply(vec4 lhs, vec4 rhs) {
    float ax = lhs.x;
    float ay = lhs.y;
    float az = lhs.z;
    float aw = lhs.w;
    float bx = rhs.x;
    float by = rhs.y;
    float bz = rhs.z;
    float bw = rhs.w;
    return vec4(
      ax * bw + aw * bx + ay * bz - az * by,
      ay * bw + aw * by + az * bx - ax * bz,
      az * bw + aw * bz + ax * by - ay * bx,
      aw * bw - ax * bx - ay * by - az * bz
    );
  }

  vec3 quat_rotateVec3(vec4 quat, vec3 rhs) {
    vec4 vq = vec4(rhs.x, rhs.y, rhs.z, 0.0);
    vec4 pq = quat_multiply(quat_multiply(quat, vq), quat_conjugate(quat));
    return vec3(pq.x, pq.y, pq.z);
  }


  mat4 tr_toMat4(vec3 tr) {
    // Note: GLSL matrices are transposed compared to the matrices in ZeaEngine
    vec4 col0 = vec4(1.0, 0.0, 0.0, 0.0);
    vec4 col1 = vec4(0.0, 1.0, 0.0, 0.0);
    vec4 col2 = vec4(0.0, 0.0, 1.0, 0.0);
    vec4 col3 = vec4(tr, 1.0);
    return mat4(col0, col1, col2, col3);
  }

  mat4 quat_toMat4(vec4 quat) {

    float x2 = quat.x + quat.x;
    float y2 = quat.y + quat.y;
    float z2 = quat.z + quat.z;

    float xx = quat.x * x2;
    float yx = quat.y * x2;
    float yy = quat.y * y2;
    float zx = quat.z * x2;
    float zy = quat.z * y2;
    float zz = quat.z * z2;
    float wx = quat.w * x2;
    float wy = quat.w * y2;
    float wz = quat.w * z2;


    // Note: GLSL matrices are transposed compared to the matrices in ZeaEngine
    vec4 col0 = vec4(
      1.0 - yy - zz,
      yx - wz,
      zx + wy,
      0.0);

    vec4 col1 = vec4(
      yx + wz,
      1.0 - xx - zz,
      zy - wx,
      0.0);

    vec4 col2 = vec4(
      zx - wy,
      zy + wx,
      1.0 - xx - yy,
      0.0);

    vec4 col3 = vec4(
      0.0,
      0.0,
      0.0,
      1.0);

    // return mat4(col0, col1, col2, col3);
    return transpose(mat4(col0, col1, col2, col3));
  }

  mat4 sc_toMat4(vec3 sc) {
    // Note: GLSL matrices are transposed compared to the matrices in ZeaEngine
    return mat4(
      sc.x, 0.0,  0.0,  0.0,
      0.0,  sc.y, 0.0,  0.0,
      0.0,  0.0,  sc.z, 0.0,
      0.0,  0.0,  0.0,  1.0);
  }

  struct Xfo {
    vec3 tr;
    vec4 ori;
    vec3 sc;
  };

  Xfo xfo_multiply(Xfo xfo, Xfo other){
    return Xfo(
      xfo.tr + quat_rotateVec3(xfo.ori, xfo.sc * other.tr),
      quat_multiply(xfo.ori, other.ori),
      xfo.sc * other.sc
    );
  }

  mat4 xfo_toMat4(Xfo xfo){
    mat4 sc_mat4 = sc_toMat4(xfo.sc);
    mat4 ori_mat4  = quat_toMat4(xfo.ori);
    mat4 tr_mat4  = tr_toMat4(xfo.tr);
    mat4 result = tr_mat4 * ori_mat4 * sc_mat4;
    return result;
  }

`
)
