import { Lines } from '@zeainc/zea-engine'

/** Class representing a hull.
 * @extends Lines
 * @ignore
 */
class Hull extends Lines {
  /**
   * Create a hull.
   * @param {number} numCPsU - The numCPsU value.
   * @param {number} numCPsV - The numCPsV value.
   */
  constructor(numCPsU = 1, numCPsV = 1) {
    super()
    this.__numSpansU = numCPsU - 1
    this.__numSpansV = numCPsV - 1
    this.__rebuild()
  }

  /**
   * The __rebuild method.
   * @private
   */
  __rebuild() {
    this.setNumVertices((this.__numSpansU + 1) * (this.__numSpansV + 1))
    this.setNumSegments(this.__numSpansU * (this.__numSpansV + 1) + (this.__numSpansU + 1) * this.__numSpansV)

    let idx = 0
    for (let j = 0; j <= this.__numSpansV; j++) {
      for (let i = 0; i < this.__numSpansU; i++) {
        const v0 = (this.__numSpansU + 1) * j + i
        const v1 = (this.__numSpansU + 1) * j + (i + 1)
        this.setSegmentVertexIndices(idx, v0, v1)
        idx++
      }
    }
    for (let j = 0; j < this.__numSpansV; j++) {
      for (let i = 0; i <= this.__numSpansU; i++) {
        const v0 = (this.__numSpansU + 1) * j + i
        const v1 = (this.__numSpansU + 1) * (j + 1) + i
        this.setSegmentVertexIndices(idx, v0, v1)
        idx++
      }
    }
  }
}

export { Hull }
// export default Hull;
