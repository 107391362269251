/* eslint-disable camelcase */
import { Color } from '@zeainc/zea-engine'
import './GLSLCADConstants.js'
import './GLSLMath.js'
import './GLSLCADSurfaceDrawing.js'

const GLDrawCADCurveShader_VERTEX_SHADER = `
precision highp float;

<%include file="GLSLUtils.glsl"/>
<%include file="GLSLCADConstants.glsl"/>
<%include file="stack-gl/transpose.glsl"/>
<%include file="stack-gl/inverse.glsl"/>

attribute vec3 positions;
instancedattribute vec4 drawCoords;  // body ID, Surface index in Body, Surface Id, TrimSet Id
// instancedattribute vec2 drawItemTexAddr;  // Address of the data in the draw item texture. (mat4)

uniform mat4 viewMatrix;
uniform mat4 cameraMatrix;
uniform mat4 projectionMatrix;
uniform int edgeDetail;
uniform vec3 assetCentroid;


// #define DEBUG_SURFACES
uniform int numCurvesInLibrary;


<%include file="GLSLCADGeomDrawing.vertexShader.glsl"/>

// GEOM
uniform sampler2D curvesAtlasLayoutTexture;
uniform ivec2 curvesAtlasLayoutTextureSize;


uniform sampler2D curvesAtlasTexture;
uniform ivec2 curvesAtlasTextureSize;
// uniform sampler2D normalsTexture;

vec3 getCurveVertex(ivec2 addr, int vertexId) {
  return fetchTexel(curvesAtlasTexture, curvesAtlasTextureSize, ivec2(addr.x + vertexId, addr.y)).rgb;
}

// vec3 getCurveTangent(vec2 surfacePatchCoords, vec2 vertexCoord) {
//   return fetchTexel(normalsTexture, curvesAtlasTextureSize, ivec2(ftoi(surfacePatchCoords.x + vertexCoord.x), ftoi(surfacePatchCoords.y + vertexCoord.y))).rgb;
// }

varying vec4 v_drawCoords;
varying vec3 v_viewPos;
varying vec3 v_worldPos;

void main(void) {
    int cadBodyId = ftoi(drawCoords.r);
    int drawItemIndexInBody = ftoi(drawCoords.g);
    int curveId = ftoi(drawCoords.b);
    int trimSetId = ftoi(drawCoords.a);
    v_drawCoords = drawCoords;

    vec2 texCoords = positions.xy;

    vec4 cadBodyPixel0 = getCADBodyPixel(cadBodyId, 0);
    vec4 cadBodyPixel1 = getCADBodyPixel(cadBodyId, 1);

    // int bodyDescId = ftoi(cadBodyPixel0.r);
    int flags = ftoi(cadBodyPixel0.g);

    // vec4 metadata = getDrawItemData(0);
    // ivec4 curveAtlasCoords = ftoi(getDrawItemData(2));
    // int flags = int(floor(metadata.a + 0.5));

    //////////////////////////////////////////////
    // Visibility
    if(testFlag(flags, BODY_FLAG_INVISIBLE)) {
        gl_Position = vec4(-3.0, -3.0, -3.0, 1.0);;
        return;
    }

    //////////////////////////////////////////////
    // Transforms
#ifdef DEBUG_SURFACES
    mat4 modelMatrix = mat4(1.0);
    int numCurvesInLibrary = 15;
    // int sideLen = int(ceil(sqrt(float(numCurvesInLibrary))));
    // int x = curveId % sideLen;
    // int y = curveId / sideLen;
    modelMatrix = mat4(1.0, 0.0, 0.0, 0.0, 
                    0.0, 1.0, 0.0, 0.0, 
                    0.0, 0.0, 1.0, 0.0,  
                    float(curveId), float(0), 0.0, 1.0);
#else

#ifdef CALC_GLOBAL_XFO_DURING_DRAW
    mat4 bodyMat = getCADBodyMatrix(cadBodyId);
    ivec2 bodyDescAddr = ftoi(cadBodyPixel0.ba);
    mat4 curveMat = getDrawItemMatrix(bodyDescAddr, drawItemIndexInBody);
    mat4 modelMatrix = bodyMat * curveMat;

    // v_sc = surfaceXfo.sc;
    //if (v_sc.z > 0.0) {
    //  gl_Position = vec4(-3.0, -3.0, -3.0, 1.0);;
    //  return;
    //}
#else
    mat4 modelMatrix = getModelMatrix();
    // Note: on mobile GPUs, we get only FP16 math in the
    // fragment shader, causing inaccuracies in modelMatrix
    // calculation. By offsetting the data to the origin
    // we calculate a modelMatrix in the asset space, and
    //  then add it back on during final drawing.
    // modelMatrix[3][0] += assetCentroid.x;
    // modelMatrix[3][1] += assetCentroid.y;
    // modelMatrix[3][2] += assetCentroid.z;
#endif
#endif
    // modelMatrix = mat4(0.001, 0.0, 0.0, 0.0, 
    //   0.0, 0.001, 0.0, 0.0, 
    //   0.0, 0.0, 0.001, 0.0,  
    //   0.0, 0.0, 0.0, 1.0);
    mat4 modelViewMatrix = viewMatrix * modelMatrix;

    //////////////////////////////////////////////
    // Vertex Attributes
    
    GLSLBinReader curvesLayoutDataReader;
    GLSLBinReader_init(curvesLayoutDataReader, curvesAtlasLayoutTextureSize, 16);
    vec4 curveDataAddr = GLSLBinReader_readVec4(curvesLayoutDataReader, curvesAtlasLayoutTexture, curveId * 8);

    int vertexId = int(positions.x * float(edgeDetail));
    vec4 pos     = vec4(getCurveVertex(ftoi(curveDataAddr.xy), vertexId), 1.0);
    // vec4 pos     = vec4(positions * float(edgeDetail), 1.0);

    // if (vertexId == 0)
    //   pos = vec4(vec3(0.0), 1.0);

    vec4 viewPos = modelViewMatrix * pos;
    v_viewPos    = viewPos.xyz;
    v_worldPos   = (modelMatrix * pos).xyz;
    gl_Position  = projectionMatrix * viewPos;

    {
        // Pull edge vertices towards us ever so slightly...
        gl_Position.z *= 0.99999;
    }
}`

const GLDrawCADCurveShader_FRAGMENT_SHADER = `
precision highp float;

<%include file="math/constants.glsl"/>
<%include file="GLSLUtils.glsl"/>
<%include file="stack-gl/gamma.glsl"/>
<%include file="materialparams.glsl"/>
<%include file="GGX_Specular.glsl"/>
<%include file="PBRSurfaceRadiance.glsl"/>

<%include file="GLSLCADConstants.glsl"/>

uniform mat4 cameraMatrix;

uniform bool headLighting;
uniform bool displayWireframes;
uniform bool displayEdges;
uniform vec4 edgeColor;

#ifdef ENABLE_INLINE_GAMMACORRECTION
uniform float exposure;
uniform float gamma;
#endif

varying vec4 v_drawCoords;
varying vec3 v_viewPos;
varying vec3 v_worldPos;

<%include file="GLSLCADGeomDrawing.fragmentShader.glsl"/>

vec3 getDebugColor(int id){
    
  int sel = int(round(mod(float(id), 14.0)));
  
  if(sel==0)
      return vec3(0.0, 1.0, 1.0);
  else if (sel==1)
      return vec3(0.0, 1.0, 0.0);
  else if (sel==2)
      return vec3(1.0, 0.0, 1.0);
  else if (sel==3)
      return vec3(0.75, 0.75, 0.0);
  else if (sel==4)
      return vec3(0.0, 0.75, 0.75);
  else if (sel==5)
      return vec3(0.75, 0.0, 0.75);
  else if (sel==6)
      return vec3(0.45, 0.95, 0.0);
  else if (sel==7)
      return vec3(0.0, 0.45, 0.95);
  else if (sel==8)
      return vec3(0.95, 0.0, 0.45);
  else if (sel==9)
      return vec3(0.95, 0.45, 0.0);
  else if (sel==10)
      return vec3(0.0, 0.95, 0.45);
  else if (sel==11)
      return vec3(0.45, 0.0, 0.95);
  else if (sel==12)
      return vec3(0.45, 0.45, 0.95);
  else if (sel==13)
      return vec3(0.0, 0.0, 0.45);
  else if (sel==14)
      return vec3(0.0, 0.45, 0.45);
  else if (sel==15)
      return vec3(0.45, 0.0, 0.45);
  else return vec3(0.2, 0.2, 0.2);
}

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
    vec4 fragColor;
#endif

    int cadBodyId = int(floor(v_drawCoords.r + 0.5));
    int drawItemIndexInBody = int(floor(v_drawCoords.g + 0.5));
    int curveId = int(floor(v_drawCoords.b + 0.5));

    // TODO: pass as varying from pixel shader.
    vec4 cadBodyPixel0 = getCADBodyPixel(cadBodyId, 0);
    int flags = int(floor(cadBodyPixel0.g + 0.5));
            

    //////////////////////////////////////////////
    // Cutaways
    if (testFlag(flags, BODY_FLAG_CUTAWAY)) {
        vec4 cadBodyPixel6 = getCADBodyPixel(cadBodyId, 6);
        vec3 cutNormal = cadBodyPixel6.xyz;
        float cutPlaneDist = cadBodyPixel6.w;
        if (cutaway(v_worldPos, cutNormal, cutPlaneDist)) {
            discard;
            return;
        }
    }

    fragColor = edgeColor;

    // fragColor = vec4(0.0, 0.0, 0.0, 1.0);
    // if (v_sc.x < 0.0) {
    //   fragColor.r = 1.0;
    // }
    // if (v_sc.y < 0.0) {
    //   fragColor.g = 1.0;
    // }
    // if (v_sc.z < 0.0) {
    //   fragColor.b = 1.0;
    // }

    /////////////////
    // Debug drawItemIndexInBody
    // {
    //   fragColor.rgb = mix(vec3(1.,1.,1.), getDebugColor(drawItemIndexInBody), float(drawItemIndexInBody)/5.0);
    // }

#ifdef ENABLE_INLINE_GAMMACORRECTION
    fragColor.rgb = toGamma(fragColor.rgb * exposure, gamma);
#endif

#ifndef ENABLE_ES3
    gl_FragColor = fragColor;
#endif
}
`

import { GLCADShader } from './GLCADShader.js'

/** Class representing a GL draw CAD surface shader.
 * @extends GLCADShader
 * @ignore
 */
class GLDrawCADCurveShader extends GLCADShader {
  /*
   * Create a GL draw CAD surface shader.
   * @param {any} gl - The gl value.
   */
  constructor(gl) {
    super(gl)

    this.setShaderStage('VERTEX_SHADER', GLDrawCADCurveShader_VERTEX_SHADER)
    this.setShaderStage('FRAGMENT_SHADER', GLDrawCADCurveShader_FRAGMENT_SHADER)
  }

  /**
   * The getParamDeclarations method.
   * @return {any} - The return value.
   */
  static getParamDeclarations() {
    const paramDescs = super.getParamDeclarations()
    paramDescs.push({
      name: 'BaseColor',
      defaultValue: new Color(1.0, 1.0, 0.5),
    })
    paramDescs.push({
      name: 'EmissiveStrength',
      defaultValue: 0.0,
    })
    paramDescs.push({
      name: 'Metallic',
      defaultValue: 0.0,
    })
    paramDescs.push({
      name: 'Roughness',
      defaultValue: 0.25,
    })
    paramDescs.push({
      name: 'Normal',
      defaultValue: new Color(0.0, 0.0, 0.0),
    })
    paramDescs.push({
      name: 'TexCoordScale',
      defaultValue: 1.0,
      texturable: false,
    })
    // F0 = reflectance and is a physical property of materials
    // It also has direct relation to IOR so we need to dial one or the other
    // For simplicity sake, we don't need to touch this value as metalic can dictate it
    // such that non metallic is mostly around (0.01-0.025) and metallic around (0.7-0.85)
    paramDescs.push({
      name: 'Reflectance',
      defaultValue: 0.025,
    })
    return paramDescs
  }

  /**
   * The getPackedMaterialData method.
   * @param {any} material - The material param.
   * @return {any} - The return value.
   */
  static getPackedMaterialData(material) {
    const matData = new Float32Array(8)
    const baseColor = material.getParameter('BaseColor').getValue()
    matData[0] = baseColor.r
    matData[1] = baseColor.g
    matData[2] = baseColor.b
    matData[3] = baseColor.a
    if (material.getParameter('EmissiveStrength')) {
      matData[4] = material.getParameter('Metallic').getValue()
      matData[5] = material.getParameter('Roughness').getValue()
      matData[6] = material.getParameter('Reflectance').getValue()
      matData[7] = material.getParameter('EmissiveStrength').getValue()
    } else {
      matData[5] = 1.0
    }
    return matData
  }
}

export { GLDrawCADCurveShader }
