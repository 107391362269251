import { Box3, Xfo, BinReader } from '@zeainc/zea-engine'
const bytesPerValue = 4 // 32 bit floats

/** Class representing a CAD body library.
 * @ignore
 */
class CADBodyLibrary {
  /**
   * Create a CAD body library.
   * @param {any} buffer - The buffer value.
   */
  constructor(buffer) {
    if (buffer != undefined) {
      this.setBinaryBuffer(buffer)
    }
  }

  /**
   * The setBinaryBuffers method.
   * @param {any} tocBuffer - The tocBuffer param.
   * @param {any} buffer - The buffer param.
   */
  setBinaryBuffers(tocBuffer, buffer) {
    this.__buffer = buffer
    this.__reader = new BinReader(this.__buffer)
    this.__size = Math.sqrt(buffer.byteLength / (4 * bytesPerValue)) // RGBA32 pixels

    this.__toc = tocBuffer // new Uint32Array(tocBuffer);
    this.__tocReader = new BinReader(tocBuffer)
    this.__numBodies = this.__tocReader.loadUInt32()

    // for (let i = 0; i < this.__numBodies; i++) {
    //     // console.log(JSON.stringify(this.getBodyDescData(i)));
    //     console.log(this.getBodyDescData(i));
    // }
  }

  /**
   * The getToc method.
   * @return {any} - The return value.
   */
  getToc() {
    return this.__toc
  }

  /**
   * The getBinaryBuffer method.
   * @return {any} - The return value.
   */
  getBinaryBuffer() {
    return this.__buffer
  }

  /**
   * The getNumBodies method.
   * @return {any} - The return value.
   */
  getNumBodies() {
    return this.__numBodies
  }

  /**
   * The getBodyDataTexelCoords method.
   * @param {any} bodyDescId - The bodyDescId param.
   * @return {any} - The return value.
   */
  getBodyDataTexelCoords(bodyDescId) {
    this.__tocReader.seek(4 + bodyDescId * (3 * 4))
    const x = this.__tocReader.loadUInt32()
    const y = this.__tocReader.loadUInt32()
    return {
      x,
      y,
    }
  }

  // eslint-disable-next-line require-jsdoc
  __seekBodyData(bodyDescId, offsetInBytes = 0) {
    const addr = this.getBodyDataTexelCoords(bodyDescId)
    // X, Y in pixels.

    const bytesPerPixel = 16 // RGBA32 pixels == 16 bytes perpixel
    const byteOffset = addr.x * bytesPerPixel + addr.y * bytesPerPixel * this.__size
    // console.log("__seekSurfaceData:" + bodyDescId + " byteOffset:" + (byteOffset +offset) + " pixel:" + ((byteOffset +offset)/8) + " x:" + addr.x + " y:" + addr.y);
    this.__reader.seek(byteOffset + offsetInBytes)
  }

  /**
   * The getNumSurfacesForBody method.
   * @param {any} bodyDescId - The bodyDescId param.
   * @return {any} - The return value.
   */
  getNumSurfacesForBody(bodyDescId) {
    this.__seekBodyData(bodyDescId, 6 /* bbox*/ * bytesPerValue)
    const numBodySurfaces = this.__reader.loadFloat32()
    return numBodySurfaces
  }

  /**
   * The getBodyBBox method.
   * @param {any} bodyDescId - The bodyDescId param.
   * @return {any} - The return value.
   */
  getBodyBBox(bodyDescId) {
    this.__seekBodyData(bodyDescId)
    const bbox = new Box3()
    bbox.p0 = this.__reader.loadFloat32Vec3()
    bbox.p1 = this.__reader.loadFloat32Vec3()
    return bbox
  }

  /**
   * The getBodyDescData method.
   * @param {any} bodyDescId - The bodyDescId param.
   * @return {any} - The return value.
   */
  getBodyDescData(bodyDescId, includeXfosAndColors = true) {
    this.__seekBodyData(bodyDescId)
    // console.log(this.__reader.pos() / 8);

    const bbox = new Box3()
    bbox.p0 = this.__reader.loadFloat32Vec3()
    bbox.p1 = this.__reader.loadFloat32Vec3()
    // console.log(this.__reader.pos() / 8);
    const numBodySurfaces = this.__reader.loadFloat32()
    const numBodyCurves = this.__reader.loadFloat32()
    const surfaceRefs = []
    for (let j = 0; j < numBodySurfaces; j++) {
      if (includeXfosAndColors) {
        const surfaceRef = {
          surfaceId: this.__reader.loadFloat32(),
        }
        surfaceRef.xfo = new Xfo(
          this.__reader.loadFloat32Vec3(),
          this.__reader.loadFloat32Quat(),
          this.__reader.loadFloat32Vec3()
        )
        surfaceRef.color = this.__reader.loadRGBAFloat32Color()
        surfaceRefs.push(surfaceRef)
      } else {
        surfaceRefs.push(this.__reader.loadFloat32())
        this.__reader.advance(14 * 4)
      }
    }

    const curveRefs = []
    for (let j = 0; j < numBodyCurves; j++) {
      if (includeXfosAndColors) {
        const curveRef = {
          curveId: this.__reader.loadFloat32(),
        }
        curveRef.xfo = new Xfo(
          this.__reader.loadFloat32Vec3(),
          this.__reader.loadFloat32Quat(),
          this.__reader.loadFloat32Vec3()
        )
        curveRef.color = this.__reader.loadRGBAFloat32Color()
        curveRefs.push(curveRef)
      } else {
        curveRefs.push(this.__reader.loadFloat32())
        this.__reader.advance(14 * 4)
      }
    }

    const bodyDescData = {
      bbox,
      surfaceRefs,
      curveRefs,
    }

    return bodyDescData
  }
}

export { CADBodyLibrary }
