import { shaderLibrary } from '@zeainc/zea-engine'

shaderLibrary.setShaderModule(
  'GLSLCADConstants.glsl',
  `
  const int SURFACE_FLAG_PERIODIC_U = 1; // 1<<0;
  const int SURFACE_FLAG_PERIODIC_V = 2; // 1<<1;
  const int SURFACE_FLAG_UNUSED2 = 4; // 1<<2;
  const int SURFACE_FLAG_UNUSED3 = 8; // 1<<3;
  const int SURFACE_FLAG_FLIPPED_NORMAL = 16; // 1<<4
  const int SURFACE_FLAG_FLIPPED_UV = 32; // 1<<5
  const int SURFACE_FLAG_COST_IS_DETAIL_U = 64; // 1<<6;
  const int SURFACE_FLAG_COST_IS_DETAIL_V = 128; // 1<<7;

  const int BODY_FLAG_CUTAWAY = 256; // 1<<8
  const int BODY_FLAG_INVISIBLE = 512; // 1<<9

  const int CURVE_FLAG_PERIODIC = 1; // 1<<0;
  const int CURVE_FLAG_UNUSED2 = 4; // 1<<2;
  const int CURVE_FLAG_COST_IS_DETAIL = 8;//1<<3;


  const int SURFACE_TYPE_PLANE = 0;
  const int SURFACE_TYPE_CONE = 1;
  const int SURFACE_TYPE_CYLINDER = 2;
  const int SURFACE_TYPE_SPHERE = 3;
  const int SURFACE_TYPE_TORUS = 4;
  const int SURFACE_TYPE_LINEAR_EXTRUSION = 5;
  const int SURFACE_TYPE_REVOLUTION = 6;
  const int SURFACE_TYPE_BEZIER_SURFACE = 7;
  const int SURFACE_TYPE_NURBS_SURFACE = 8;
  const int SURFACE_TYPE_OFFSET_SURFACE = 9;
  const int SURFACE_TYPE_TRIMMED_RECT_SURFACE = 10;

  const int SURFACE_TYPE_POLY_PLANE = 14;
  const int SURFACE_TYPE_FAN = 15;
  const int SURFACE_TYPE_REVOLUTION_FLIPPED_DOMAIN = 16;


  const int CURVE_TYPE_LINE = 20;
  const int CURVE_TYPE_CIRCLE = 21;
  const int CURVE_TYPE_ELIPSE = 22;
  // const int CURVE_TYPE_HYPERBOLA = 23;
  // const int CURVE_TYPE_PARABOLA = 24;
  // const int CURVE_TYPE_BEZIERCURVE = 25;
  const int CURVE_TYPE_NURBS_CURVE = 26;
  // const int CURVE_TYPE_OFFSET_CURVE = 27;
  // const int CURVE_TYPE_TRIMMED_CURVE = 28;


  const int geomLibraryHeaderSize = 4; // 2 pixels at the start of the GeomLibrary and CurveLibrary

  // [bodyDescId, surfaceId, cadBodyDesc.xy], [glmaterialcoords.xy][tr-xyz], [ori], [sc], [highlight], [cutPlane]
  const int pixelsPerCADBody = 7;
`
)
