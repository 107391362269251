import {
  Color,
  Box3,
  MaterialParameter,
  ColorParameter,
  BaseGeomItem,
  Material,
  GeomItem,
  TreeItem,
  Registry,
} from '@zeainc/zea-engine'

/**
 * Represents a Body within a CAD Part. A Body is made up of either a single mesh or a collection of meshes, one for each surface.
 * When a zcad file is produced, the tool can  optimize bodies to contain only one mesh to speed up loading of large models, and support bigger models being loaded.
 *
 * **Parameters**
 * * **Material(`MaterialParameter`):** Specifies the material of the geometry item.
 * * **Color(`ColorParameter`):** Specifies the color of the geometry item.
 *
 * @extends BaseGeomItem
 */
class CADBody extends BaseGeomItem {
  /**
   * Creates an instance of CADBody setting up the initial configuration for Material and Color parameters.
   *
   * @param {string} name - The name value.
   * @param {CADAsset} cadAsset - The cadAsset value.
   */
  constructor(name, cadAsset) {
    super(name)
    this.__bodyDescId = -1
    this.__id = -1
    this.__bodyBBox = new Box3()
    this.__cadAsset = cadAsset // Note: used in testing scenes.
    if (this.__cadAsset) this.__cadAsset.incCADBodyCount()

    this.__materialParam = this.addParameter(new MaterialParameter('Material'))
    this.__colorParam = this.addParameter(new ColorParameter('Color', new Color(1, 0, 0, 0)))
  }

  /**
   * Returns the `CADAsset` object in current `CADBody`
   *
   * @return {CADAsset} - The return value.
   */
  getCADAsset() {
    return this.__cadAsset
  }

  /**
   * The clone method constructs a new CADBody, copies its values
   * from this item and returns it.
   *
   * @param {number} flags - The flags param.
   * @return {CADBody} - The return value.
   */
  clone(flags) {
    const cloned = new CADBody()
    cloned.copyFrom(this, flags)
    return cloned
  }

  /**
   * The copyFrom method.
   * @param {CADBody} src - The src param.
   * @param {number} flags - The flags param.
   * @private
   */
  copyFrom(src, flags) {
    super.copyFrom(src, flags)
    this.__cadAsset = src.getCADAsset()
    this.__cadAsset.incCADBodyCount()

    this.setBodyDescId(src.getBodyDescId())
    this.setMaterial(src.getMaterial()) // clone?
  }

  // ////////////////////////////////////////
  // Geometry

  /**
   * The getBodyDataTexelCoords method.
   * @param {any} bodyDescId - The bodyDescId param.
   * @return {any} - The return value.
   */
  getBodyDataTexelCoords() {
    return this.__cadAsset.getBodyLibrary().getBodyDataTexelCoords(this.__bodyDescId)
  }

  /**
   * Returns an object that contains the bBox and all the SurfaceRefs of current object using the bodyDescId.
   * @private
   * @return {object} - The return value.
   */
  getBodyDescData() {
    const bodyDescData = this.__cadAsset.getBodyLibrary().getBodyDescData(this.__bodyDescId)
    for (const surfaceRef of bodyDescData.surfaceRefs) {
      surfaceRef.surfaceType = this.__cadAsset.getSurfaceLibrary().getSurfaceTypeLabel(surfaceRef.surfaceId)
      surfaceRef.dims = this.__cadAsset.getSurfaceLibrary().getSurfaceDims(surfaceRef.surfaceId)
    }
    return bodyDescData
  }

  /**
   * Returns a list of all SurfaceRefs of current `CADBody`.
   * <br>
   * Which contain the surfaceId, xfo object and the color.
   *
   * @return {array} - The return value.
   */
  getSurfaceRefs() {
    const bodyData = this.getBodyDescData()
    return bodyData.surfaceRefs
  }

  /**
   * Returns the bodyDescId of current `CADBody`
   *
   * @return {number} - The return value.
   */
  getBodyDescId() {
    return this.__bodyDescId
  }

  /**
   * Sets bodyDescId to current `CADBody`, but also calculates a new bBox.
   * @private
   * @param {number} bodyId - The bodyId param.
   */
  setBodyDescId(bodyId) {
    this.__bodyDescId = bodyId
    if (bodyId >= 0) {
      this.__bodyBBox = this.__cadAsset.getBodyLibrary().getBodyBBox(this.__bodyDescId)
      this._setBoundingBoxDirty()
    }
  }

  /**
   * Returns current Material parameter value.
   * @deprecated
   * @return {MaterialParameter} - The return value.
   */
  getMaterial() {
    return this.__materialParam.getValue()
  }

  /**
   * Sets Material parameter value.
   * <br>
   * For `mode` possible values check `Parameter` Class documentation.
   * @see [Zea Engine]()
   *
   * @deprecated
   * @param {MaterialParameter} material - The material param.
   * @param {number} mode - The mode param.
   */
  setMaterial(material, mode) {
    this.__materialParam.setValue(material, mode)
  }

  /**
   * The _cleanBoundingBox method.
   * @param {any} bbox - The bbox param.
   * @return {any} - The return value.
   * @private
   */
  _cleanBoundingBox(bbox) {
    bbox = super._cleanBoundingBox(bbox)
    if (this.__bodyDescId != -1) {
      bbox.addBox3(this.__bodyBBox, this.getParameter('GlobalXfo').getValue())
    }
    return bbox
  }

  // ///////////////////////////
  // Persistence

  /**
   * Initializes CADBody's asset, material, version and layers; adding current `CADBody` Geometry Item toall the layers in reader
   *
   * @param {BinReader} reader - The reader param.
   * @param {object} context - The context param.
   */
  readBinary(reader, context) {
    super.readBinary(reader, context)

    // Cache only in debug mode.
    this.__cadAsset = context.assetItem
    this.__cadAsset.incCADBodyCount()

    const bodyDescId = reader.loadSInt32()
    if (bodyDescId >= 0) {
      this.setBodyDescId(bodyDescId)
    }

    if (context.versions['zea-cad'].compare([0, 0, 4]) < 0) {
      const materialName = reader.loadStr()
      // const materialName = 'Mat' + this.__bodyDescId;

      const materialLibrary = context.assetItem.getMaterialLibrary()
      let material = materialLibrary.getMaterial(materialName, false)
      if (!material) {
        // console.warn("Body :'" + this.name + "' Material not found:" + materialName);
        // material = materialLibrary.getMaterial('DefaultMaterial');

        material = new Material(materialName, 'SimpleSurfaceShader')
        material.getParameter('BaseColor').setValue(Color.random(0.25))
        context.assetItem.getMaterialLibrary().addMaterial(material)
      }
      this.getParameter('Material').setValue(material)
    }

    if (context.versions['zea-cad'].compare([0, 0, 2]) >= 0 && context.versions['zea-cad'].compare([0, 0, 4]) < 0) {
      this.__layers = reader.loadStrArray()
      // console.log("Layers:", this.__layers)
      for (const layer of this.__layers) context.addGeomToLayer(this, layer)
    }
  }

  /**
   * The generatePolygonMeshSurfaces method.
   * @param {number} lod - The lod param.
   * @return {any} - The return value.
   * @private
   */
  generatePolygonMeshSurfaces(lod = 0) {
    const treeItem = new TreeItem(this.getName())

    // const standardMaterial = new Material('surfaces', 'SimpleSurfaceShader');
    // standardMaterial.getParameter('BaseColor').setValue(Color.random(0.4));

    const bodyData = this.getBodyDescData()
    bodyData.surfaceRefs.forEach((surfaceRef, surfaceIndex) => {
      const mesh = this.__cadAsset.getSurfaceLibrary().generatePolygonSurface(surfaceRef.surfaceId, lod)
      if (mesh) {
        const geomItem = new GeomItem('Surface' + surfaceIndex + ':' + surfaceRef.surfaceId, mesh, mesh.material) // this.__material);// mesh.mat);
        geomItem.getParameter('LocalXfo').setValue(surfaceRef.xfo)
        treeItem.addChild(geomItem)
      }
    })
    return treeItem
  }

  /**
   * The generateHullGeometry method.
   * @return {any} - The return value.
   * @private
   */
  generateHullGeometry() {
    const treeItem = new TreeItem(this.getName())
    const bodyData = this.getBodyDescData()
    bodyData.surfaceRefs.forEach((surfaceRef, surfaceIndex) => {
      const hull = this.__cadAsset.getSurfaceLibrary().generateHullGeometry(surfaceRef.surfaceId)
      if (hull) {
        const geomItem = new GeomItem('Hull' + surfaceIndex + ':' + surfaceRef.surfaceId, hull, hull.material)
        geomItem.getParameter('LocalXfo').setValue(surfaceRef.xfo)
        treeItem.addChild(geomItem)
        return false
      }
    })
    return treeItem
  }

  // ////////////////////////////////////////
  // Persistence

  /**
   * The toJSON method encodes this type as a json object for persistences.
   *
   * @param {number} flags - The flags param.
   * @return {object} - The return value.
   */
  toJSON(flags = 0) {
    const j = super.toJSON(flags)
    return j
  }

  /**
   * The fromJSON method decodes a json object for this type.
   *
   * @param {object} j - The j param.
   * @param {number} flags - The flags param.
   */
  fromJSON(j, flags = 0) {
    super.fromJSON(j, flags)
  }
}

Registry.register('CADBody', CADBody)

export { CADBody }
