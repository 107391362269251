import { shaderLibrary } from '@zeainc/zea-engine'

shaderLibrary.setShaderModule(
  'GLSLCADGeomDrawing.vertexShader.glsl',
  `


uniform sampler2D cadBodiesTexture;
uniform int cadBodiesTextureSize_vert;
  
vec4 getCADBodyPixel(int cadBodyId, int pixelOffset) {
  int offset = cadBodyId * pixelsPerCADBody;
  ivec2 start;
  start.y += offset / cadBodiesTextureSize_vert;
  start.x = imod(offset, cadBodiesTextureSize_vert);
  return fetchTexel(cadBodiesTexture, ivec2(cadBodiesTextureSize_vert), ivec2(start.x + pixelOffset, start.y));
}

<%include file="GLSLMath.glsl"/>
<%include file="GLSLBinReader.glsl"/>


const int pixelsPerDrawItem = 10; // The number of RGBA pixels per draw item.
const int valuesPerSurfaceTocItem = 9;
const int bytesPerValue = 4; // 32 bit floats

// Before enabling this, enable the 2nd vertex attribute (drawItemTexAddr)
// in the Draw shader and in the GLDrawSet, and in the GLCADAssetWorker
#define CALC_GLOBAL_XFO_DURING_DRAW
#ifdef CALC_GLOBAL_XFO_DURING_DRAW

mat4 getCADBodyMatrix(int cadBodyId) {
  vec3 body_tr = getCADBodyPixel(cadBodyId, 2).rgb;
  vec4 body_ori = normalize(getCADBodyPixel(cadBodyId, 3));
  vec3 body_sc = getCADBodyPixel(cadBodyId, 4).rgb;
  Xfo bodyXfo = Xfo(body_tr, body_ori, body_sc);
  return xfo_toMat4(bodyXfo);
  // return mat4(1.0);
}

uniform sampler2D bodyDescTexture;
uniform ivec2 bodyDescTextureSize;

GLSLBinReader setupBodyDescReader(ivec2 bodyDescAddr) {
  GLSLBinReader bodyDescReader;
  ivec4 region = ivec4(0, 0, bodyDescTextureSize.x, bodyDescTextureSize.y);
  ivec2 start = ivec2(bodyDescAddr.x, bodyDescAddr.y);
  GLSLBinReader_init(bodyDescReader, bodyDescTextureSize, region, start, 32);
  return bodyDescReader;
}

Xfo getDrawItemXfo(ivec2 bodyDescAddr, int drawItemIndexInBody) {
  GLSLBinReader bodyDescReader = setupBodyDescReader(bodyDescAddr);
  
  // Skip over the bbox, numSurfaces and then to the current surface data.  
  #ifdef ENABLE_BODY_EDGES
  int offsetOfItemRef = (6/*bbox*/) + (1/*numSurfaces*/) + (1/*numCurves*/) + (drawItemIndexInBody * (1/*id*/ + 10/*xfo*/));
  #else
  int offsetOfItemRef = (6/*bbox*/) + (1/*numSurfaces*/) + (drawItemIndexInBody * (1/*id*/ + 10/*xfo*/));
  #endif
  #ifdef ENABLE_PER_FACE_COLORS
  offsetOfItemRef += drawItemIndexInBody * 4/*color*/; // Skip over the color.
  #endif
  
  vec3 surface_tr = vec3(
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+1),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+2),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+3)
    );

  vec4 surface_ori = normalize(vec4(
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+4),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+5),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+6),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+7)
    ));

  vec3 surface_sc = vec3(
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+8),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+9),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+10)
  );

  Xfo surfaceXfo = Xfo(surface_tr, surface_ori, surface_sc);
  return surfaceXfo;

}
mat4 getDrawItemMatrix(ivec2 bodyDescAddr, int drawItemIndexInBody) {
  return xfo_toMat4(getDrawItemXfo(bodyDescAddr, drawItemIndexInBody));
  // return mat4(1.0);
}

#else // CALC_GLOBAL_XFO_DURING_DRAW

uniform sampler2D drawItemsTexture;
uniform ivec2 vert_drawItemsTextureSize;

// The Draw Items texture is laid out with 8 pixels per draw item.
vec4 getDrawItemData(int offset) {
  return fetchTexel(drawItemsTexture, vert_drawItemsTextureSize, ivec2(ftoi(drawItemTexAddr.x) + offset, ftoi(drawItemTexAddr.y)));
}

mat4 getModelMatrix() {
  // Unpack 3 x 4 matix columns into a 4 x 4 matrix.
  vec4 col0 = getDrawItemData(0);
  vec4 col1 = getDrawItemData(1);
  vec4 col2 = getDrawItemData(2);
  mat4 result = mat4(col0, col1, col2, vec4(0.0, 0.0, 0.0, 1.0));
  return transpose(result);
}

#endif // CALC_GLOBAL_XFO_DURING_DRAW

  `
)
shaderLibrary.setShaderModule(
  'GLSLCADSurfaceDrawing.vertexShader.glsl',
  `
  
<%include file="GLSLCADGeomDrawing.vertexShader.glsl"/>


// GEOM
uniform sampler2D surfaceAtlasLayoutTexture;
uniform ivec2 surfaceAtlasLayoutTextureSize;

uniform sampler2D surfacesAtlasTexture;
uniform ivec2 surfacesAtlasTextureSize;
uniform sampler2D normalsTexture;

vec4 getSurfaceVertex(vec2 surfacePatchCoords, vec2 vertexCoord) {
  return fetchTexel(surfacesAtlasTexture, surfacesAtlasTextureSize, ivec2(ftoi(surfacePatchCoords.x + vertexCoord.x), ftoi(surfacePatchCoords.y + vertexCoord.y)));
}

vec3 getSurfaceNormal(vec2 surfacePatchCoords, vec2 vertexCoord) {
  return fetchTexel(normalsTexture, surfacesAtlasTextureSize, ivec2(ftoi(surfacePatchCoords.x + vertexCoord.x), ftoi(surfacePatchCoords.y + vertexCoord.y))).rgb;
}

`
)

shaderLibrary.setShaderModule(
  'GLSLCADGeomDrawing.fragmentShader.glsl',
  `
  uniform sampler2D cadBodiesTexture;
  uniform int cadBodiesTextureSize_frag;
  
  vec4 getCADBodyPixel(int cadBodyId, int pixelOffset) {
    
    int offset = cadBodyId * pixelsPerCADBody;
    ivec2 start;
    start.y += offset / cadBodiesTextureSize_frag;
    start.x = imod(offset, cadBodiesTextureSize_frag);
  
    return fetchTexel(cadBodiesTexture, ivec2(cadBodiesTextureSize_frag), ivec2(start.x + pixelOffset, start.y));
  }
  
  // Is this still used?
  uniform sampler2D drawItemsTexture;
  uniform ivec2 frag_drawItemsTextureSize;
  // The Draw Items texture is laid out with 8 pixels per draw item.
  vec4 getDrawItemData(int offset) {
    return fetchTexel(drawItemsTexture, frag_drawItemsTextureSize, ivec2(ftoi(v_drawCoords.x) + offset, ftoi(v_drawCoords.y)));
  }
  
  

//////////////////////////////////////////////
// Cutaways

<%include file="cutaways.glsl"/>

// bool applyCutaway(int cadBodyId, int flags) {
//   if (testFlag(flags, BODY_FLAG_CUTAWAY)) {
//     vec4 cadBodyPixel6 = getCADBodyPixel(cadBodyId, 6);
//     vec3 cutNormal = cadBodyPixel6.xyz;
//     float cutPlaneDist = cadBodyPixel6.w;
//     if (cutaway(v_worldPos, cutNormal, cutPlaneDist)) {
//         discard;
//     }
//     return true;
//   }
//   return false;
// }

// int applyCutaway(int flags, bool backFacing, vec3 cutColor, inout vec4 fragColor) {
//   bool cut = testFlag(flags, BODY_FLAG_CUTAWAY);
//   if(cut){
//     if(cutaway(v_worldPos, cutNormal, planeDist)) {
//       return 1;
//     }
//     if(backFacing){
//       fragColor = vec4(cutColor, 1.0);
//       return 2;
//     }
//   }
//   return 0;
// }
  `
)
shaderLibrary.setShaderModule(
  'GLSLCADSurfaceDrawing.fragmentShader.glsl',
  `
  
<%include file="GLSLCADGeomDrawing.fragmentShader.glsl"/>


uniform sampler2D materialsTexture;
uniform ivec2 materialsTextureSize;

vec4 getMaterialValue(vec2 materialCoords, int valueIndex) {
  return fetchTexel(materialsTexture, materialsTextureSize, ivec2(ftoi(materialCoords.x) + valueIndex, ftoi(materialCoords.y)));
}


//////////////////////////////////////////////
// Surface Colors

uniform sampler2D bodyDescTexture;
uniform ivec2 bodyDescTextureSize_frag;

GLSLBinReader setupBodyDescReader(ivec2 bodyDescAddr) {
  GLSLBinReader bodyDescReader;
  ivec4 region = ivec4(0, 0, bodyDescTextureSize_frag.x, bodyDescTextureSize_frag.y);
  ivec2 start = ivec2(bodyDescAddr.x, bodyDescAddr.y);
  GLSLBinReader_init(bodyDescReader, bodyDescTextureSize_frag, region, start, 32);
  return bodyDescReader;
}

vec4 getDrawItemColor(ivec2 bodyDescAddr, int drawItemIndexInBody) {
  GLSLBinReader bodyDescReader = setupBodyDescReader(bodyDescAddr);
  
  #ifdef ENABLE_BODY_EDGES
  int offsetOfItemRef = (6/*bbox*/) + (1/*numSurfaces*/) + (1/*numCurves*/) + (drawItemIndexInBody * (1/*id*/ + 10/*xfo*/ + 4/*color*/));
  #else
  int offsetOfItemRef = (6/*bbox*/) + (1/*numSurfaces*/) + (drawItemIndexInBody * (1/*id*/ + 10/*xfo*/ + 4/*color*/));
  #endif

  vec4 color = vec4(
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+11),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+12),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+13),
    GLSLBinReader_readFloat(bodyDescReader, bodyDescTexture, offsetOfItemRef+14)
  );

  return color;
}

//////////////////////////////////////////////
// Trimming
uniform sampler2D trimSetsAtlasLayoutTexture;
uniform ivec2 trimSetsAtlasLayoutTextureSize;

uniform sampler2D trimSetAtlasTexture;
uniform ivec2 trimSetAtlasTextureSize;

bool applyTrim(vec4 trimPatchQuad, inout vec3 trimCoords, int flags) {
  if(trimPatchQuad.z > 0.0 && trimPatchQuad.w > 0.0){
    // Remove cobwebs along borders.
    // Tis appears to eliminate cobwebs along borders of trim sets. 
    // It does indicate that a math eror exists somewhere else
    // that we would get cobwebs here.
    // To repro, load Dead Eye Bearing and zoom out.
    if (v_textureCoord.x < 0.0 || v_textureCoord.x >= 1.0 || v_textureCoord.y < 0.0 || v_textureCoord.y >= 1.0)
      return true;

    trimCoords.xy = trimPatchQuad.xy + (trimPatchQuad.zw * v_textureCoord);

    vec2 trimUv = (trimCoords.xy) / vec2(trimSetAtlasTextureSize);
    vec4 trimTexel = texture2D(trimSetAtlasTexture, trimUv);

    trimCoords.z = max(trimTexel.r, trimTexel.g);
    
    if (trimTexel.r < 0.5 || trimTexel.g < 0.5) {
      return true;
    }
    return false;
  }
  else {
    // This is a non-trimmed surface, so return false.
    trimCoords = vec3(-1.0);
    return false;
  }
}


`
)
