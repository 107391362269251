/* eslint-disable camelcase */
import { GLShader, shaderLibrary, Registry } from '@zeainc/zea-engine'

/** Class representing a GL evaluate CAD curve shader.
 * @extends GLShader
 * @ignore
 */
class GLEvaluateCADCurveShader extends GLShader {
  /**
   * Create a GL evaluate CAD curve shader.
   * @param {any} gl - The gl value.
   * @ignore
   */
  constructor(gl) {
    super(gl)

    this.__shaderStages['VERTEX_SHADER'] = shaderLibrary.parseShader(
      'GLEvaluateCADCurveShader.vertexShader',
      `
precision highp float;

attribute vec3 positions;
instancedattribute vec4 patchCoords;         // where the values will be written to in the target texture.
instancedattribute vec2 curveDataCoords;     // where the data will come from in the source texture

uniform ivec2 curvesAtlasTextureSize;

/* VS Outputs */
varying vec2 v_geomDataCoords;
varying vec2 v_patchSize;
varying vec2 v_vertexCoord;



void main(void) {

  vec2 patchPos = patchCoords.xy;
  v_patchSize = patchCoords.zw;

  v_geomDataCoords = curveDataCoords;
  v_vertexCoord = (positions.xy + 0.5) * v_patchSize;

  vec2 pos = (patchPos + v_vertexCoord) / vec2(curvesAtlasTextureSize);
  gl_Position =  vec4((pos - 0.5) * 2.0, 0.0, 1.0);
}
`
    )

    this.__shaderStages['FRAGMENT_SHADER'] = shaderLibrary.parseShader(
      'GLEvaluateCADCurveShader.fragmentShader',
      `
// #extension GL_EXT_draw_buffers : require
precision highp float;

/* VS Outputs */
varying vec2 v_geomDataCoords;
varying vec2 v_patchSize;
varying vec2 v_vertexCoord;


struct PosNorm {
  vec3 pos;
  vec3 normal;
  int geomType;
};


<%include file="GLSLUtils.glsl"/>
<%include file="GLSLMath.glsl"/>
<%include file="GLSLBinReader.glsl"/>

<%include file="GLSLCADConstants.glsl"/>
<%include file="GLSLCADCurves.glsl"/>
<%include file="GLSLNURBS.glsl"/>
<%include file="GLSLNURBSCurves.glsl"/>

uniform sampler2D curveDataTexture;
uniform ivec2 curveDataTextureSize;
uniform int writeTangents;

PosNorm evalCADCurves(vec2 params, inout GLSLBinReader reader, sampler2D texture) {
  int geomType = GLSLBinReader_readInt(reader, texture);

  // PosNorm result;
  // result.pos = vec3(float(geomType));
  // // fragColor = reader.buffer;
  // // fragColor = vec4(float(reader.textureSize.x), float(reader.textureSize.y), float(geomType), 1.0);
  // return result;


  if(geomType == CURVE_TYPE_LINE) {
      return calcLinePoint(params.x, reader, texture);
  } 
  if(geomType == CURVE_TYPE_CIRCLE) {
      return calcCirclePoint(params.x, reader, texture);
  } 
  if(geomType == CURVE_TYPE_ELIPSE) {
      return calcElipsePoint(params.x, reader, texture);
  }
  if(geomType == CURVE_TYPE_NURBS_CURVE) {
      return calcNURBSCurve3dPoint(params.x, reader, texture);
  } 
  
  PosNorm detault;
  return detault;
}

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
  vec4 fragColor;
#endif


  // Evaluate the curve per vertex

  // compute exact xy coords per pixel by rounding the vertex coord to the nearest integer and then dividing my patch size.
  // The interpollated xy coords from the quad are not exact because the quad must cover the pixels with some margin.

  // The quad overlaps the pixels by half a pixel, so 
  vec2 params = vec2(floor(v_vertexCoord.x), floor(v_vertexCoord.y));
  if(v_patchSize.x > 1.0)
      params.x /= v_patchSize.x - 1.0;
  // if(v_patchSize.y > 1.0)
  //     params.y /= v_patchSize.y - 1.0;

  ivec4 region = ivec4(0, 0, curveDataTextureSize.x, curveDataTextureSize.y);
  ivec2 start = ivec2(int(v_geomDataCoords.x), int(v_geomDataCoords.y));

  GLSLBinReader reader;
  GLSLBinReader_init(reader, curveDataTextureSize, region, start, 32);
  PosNorm posNorm = evalCADCurves(params, reader, curveDataTexture);

  if(writeTangents == 1) {
      fragColor = vec4(posNorm.normal, 1.0);
  }
  else {
      fragColor = vec4(posNorm.pos, 1.0);
  }
  // fragColor = vec4(params.x, params.y, 0.0, 1.0);
  // gl_FragData[0] = vec4(posNorm.pos, 1.0);
  // gl_FragData[1] = vec4(posNorm.normal, 1.0);

  // fragColor.r = v_geomDataCoords.x;
  // fragColor.g = v_geomDataCoords.y;
  // fragColor.b = float(curveDataTextureSize.x);
  // fragColor.a = float(curveDataTextureSize.y);

#ifndef ENABLE_ES3
  gl_FragColor = fragColor;
#endif
}
`
    )
  }
}

Registry.register('GLEvaluateCADCurveShader', GLEvaluateCADCurveShader)

export { GLEvaluateCADCurveShader }
